
import { getBarcode } from '@/utils/api'
import { BARCODE_SCAN, FAIL_SOUND, QRCODE_BARCODE_TYPE_REGEX, SUCCESS_SOUND } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { displayTargetAlert, openMessage, promptVideoPermission } from '@/utils/helpers'
import { BrowserMultiFormatReader, Result } from '@zxing/library'
import { Howl } from 'howler'
import { Barcode, BarcodeCapture } from 'scandit-web-datacapture-barcode'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IAPIErrorCode, IBarcode, IBarcodeDefinitionType, TError } from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import BarcodeReaderMixin from './BarcodeReaderMixin.vue'

interface IScanSound {
  play: Function
  once: Function
}

@Options({
  components: {},
  name: 'MultipleBarcodeScanMixin',
})
export default class MultipleBarcodeScanMixin extends mixins(BarcodeReaderMixin) {
  scannedBarcodeList: IBarcode[] = []
  currentPlainText = ''
  scannedBarcode = ''
  video: HTMLVideoElement | null = null
  _barcodeDataCapture: BarcodeCapture | undefined
  barcodes = [] as IBarcode[]
  successScanSound: IScanSound = new Howl({
    src: [SUCCESS_SOUND],
    onplayerror: (soundId: string, errMessage: string) => {
      this.successScanSound.once('unlock', () => this.successScanSound.play())
    },
  })

  failScanSound: IScanSound = new Howl({
    src: [FAIL_SOUND],
    onplayerror: (soundId: string, errMessage: string) => {
      this.successScanSound.once('unlock', () => this.successScanSound.play())
    },
  })

  codeReader: BrowserMultiFormatReader | null = new BrowserMultiFormatReader(
    undefined,
    BARCODE_SCAN.timeBetweenScansMillis as number
  )

  pictureWidth = 320
  pictureHeight = 320

  defaultScanReader(result: Result, codeReader: BrowserMultiFormatReader) {
    this.codeReader = codeReader
    if (!result) return

    const temp: { [key: string]: unknown } = { ...result }
    this.handleGotScanBarcode((temp.text as string) || '')
  }

  scanditDataResult(symbology: Barcode) {
    if (!symbology) {
      // TODO: Do something when scanResult unavailable
    }
    this.handleGotScanBarcode((symbology?.data as string) || '')
  }

  async setupVideo() {
    if (!this.video) {
      this.video = document.getElementById('video') as HTMLVideoElement
    }
    const videoSettings = {
      video: {
        facingMode: 'environment',
        aspectRatio: 1,
      },
    }

    await navigator.mediaDevices
      .getUserMedia(videoSettings)
      .then((stream) => {
        // Setup the video stream
        if (this.video) {
          this.video.srcObject = stream
          const streamSetting = stream.getVideoTracks()[0].getSettings()
          // actual width & height of the camera video
          this.pictureWidth = streamSetting.width || this.pictureWidth
          this.pictureHeight = streamSetting.height || this.pictureHeight
        }
      })
      .catch((e) => {
        errorHandler(e.name === 'NotAllowedError' ? 'camera_access_not_allow' : e)
      })
  }

  async mounted() {
    try {
      this.isReadyToScan = false
      if (this.isScanditReaderProject) {
        await this.initScanditReader()
      } else {
        await promptVideoPermission()

        await this.setupVideo()
        if (!this.video?.srcObject) return
        await this.initZxingReader()
      }
    } catch (error) {
      errorHandler(error as TError)
    } finally {
      this.isReadyToScan = true
    }
  }

  openErrorToast(message: string) {
    openMessage(message, 'error', 0)
  }

  get currentBarcodeId(): string {
    return this.$store.state.barcode?.barcode?.id
  }

  validateBarcode(resultScanned: IBarcode) {
    throw Error('This function need to implement on component ' + resultScanned)
  }

  async handleGotScanBarcode(plainText: string) {
    if (plainText === this.currentPlainText) return

    this.currentPlainText = plainText
    try {
      const regexBarcodeId = QRCODE_BARCODE_TYPE_REGEX
      let barcodeId = ''
      let externalId
      if (plainText.match(regexBarcodeId)) {
        const urlParts = plainText.split('/')
        barcodeId = urlParts.pop() || ''
        // Scanned barcode is current barcode, skip it
        if (barcodeId === this.currentBarcodeId) return

        // Skip barcode which one already added on previous scan
        if (this.scannedBarcodeList.find((val) => val.id === barcodeId)) return
      } else {
        const detectedText = plainText || ''
        const projectCode = this.$route?.params?.project.split('@')
        if (projectCode.length > 1) projectCode.pop()

        barcodeId = `projectCode=${projectCode[0]}`
        externalId = detectedText
      }
      const resultScanned: IBarcode = await getBarcode(barcodeId, externalId)
      if (!resultScanned) throw this.$t('barcode does not exist')

      if (
        this.bulkUpdateOperationLimitCount &&
        this.scannedBarcodeList.length >= this.bulkUpdateOperationLimitCount.maxValue
      ) {
        throw this.$t('bulk_tracking_over_barcodes_is_not_allowed', {
          count: this.bulkUpdateOperationLimitCount.maxValue,
        })
      }

      await this.validateBarcode(resultScanned)

      // Display target barcode notifications if any.
      await displayTargetAlert(resultScanned)

      this.scannedBarcode = resultScanned.id
      if (typeof this.addBarcode === 'function') {
        this.addBarcode(resultScanned.id)
      }
      if (!this.scannedBarcodeList.find((bc) => bc.id === resultScanned?.id)) {
        this.scannedBarcodeList.push(resultScanned)
        this.onNewScannedBarcode(resultScanned)

        openMessage(this.$t('barcode detected'), 'success')
      }
    } catch (error) {
      this.currentPlainText = ''
      this.failScanSound.play()
      if (error === 'cancel') return
      let message

      // const error = err as TError
      if (typeof error === 'object' && error?.hasOwnProperty('id')) {
        message = this.$t(`errors.${(error as IAPIErrorCode)?.id?.pop()}`)
      } else if (typeof error === 'string') {
        const modelObject = this.errorVariableTemplate()
        errorHandler(error as TError, modelObject)
        return
      } else if (!isEmpty(error)) {
        message = JSON.stringify(error)
      }
      if (message && message.trim() !== '') {
        this.openErrorToast(message)
      }
    }
  }

  // We will implement error variable template according action here
  errorVariableTemplate() {
    return {
      barcodeType: this.barcodeName,
      fTP1: this.currentTrackPointName,
      tTP2: this.nextSelectTrackingPointName,
    }
  }

  get project() {
    return this.$store.state.project.details
  }

  get barcodeType(): IBarcodeDefinitionType {
    return this.project?.barcodeTypes?.[this.barcode?.barcodeType]
  }

  get barcodeName(): string {
    return this.barcodeType?.name || ''
  }

  get currentTrackPointName() {
    return this.project?.trackPoints[this.currentTrackPointKey]?.name
  }

  get currentTrackPointKey() {
    return this.barcode?.currentTrackPointKey
  }

  onNewScannedBarcode(bc: IBarcode) {
    const found = this.barcodes.find((barcode) => barcode.id === bc.id)

    this.successScanSound.play()

    if (!found && bc?.id !== this.barcode?.id) this.barcodes.push(bc)
  }
}
