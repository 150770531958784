<template>
<div class="target-area">
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40 0H20C8.95431 0 0 8.95431 0 20V40H5V20C5 11.7157 11.7157 5 20 5H40V0ZM195 40V20C195 11.7157 188.284 5 180 5H160V0H180C191.046 0 200 8.95431 200 20V40H195ZM160 195H180C188.284 195 195 188.284 195 180V160H200V180C200 191.046 191.046 200 180 200H160V195ZM5 160V180C5 188.284 11.7157 195 20 195H40V200H20C8.95431 200 0 191.046 0 180V160H5Z"
      fill="white"
    />
  </svg>
</div>
</template>

<style lang="scss" scoped>
.target-area {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  width: 200px;
  height: 200px;
}
</style>
