
import BarcodeTypesMixin from '@/components/mixins/BarcodeTypesMixin.vue'
import { toSnakeCase } from '@/utils/helpers'
import isEmpty from 'lodash/isEmpty'
import { parseCustomFieldsValue } from 'smartbarcode-web-core/src/utils/barcodeHelper'
import {
  IActivationData,
  IBarcodeDefinitionType,
  IDimension,
  IProduct,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'

@Options({
  components: {},
  name: 'BarcodeKeyValueInfoMixin',
  methods: { toSnakeCase },
})
export default class BarcodeKeyValueInfoMixin extends BarcodeTypesMixin {
  getDisplayValue(value: string | number) {
    if (typeof value === 'number') {
      return value.toFixed(1)
    }
    return value
  }

  get barcodeTypeInfo(): IBarcodeDefinitionType | undefined {
    return this.currentBarcodeTypeInfo(this.barcode)
  }

  get trackingNumberLabel(): string {
    return this.barcodeTypeInfo?.activationFields?.trackingNumber?.label || this.$t('Tracking Number')
  }

  get product() {
    let product
    if (this.barcode?.product?.id) {
      const products = this.$store.state.project.products
      product = products.find((e: IProduct) => e.id === this.barcode?.product?.id)
    }

    return {
      ...(product && { productName: product.name }),
    }
  }

  get dimension() {
    try {
      let product
      if (this.barcode?.product?.id) {
        const products = this.$store.state.project.products
        product = products.find((e: IProduct) => e.id === this.barcode?.product?.id) || this.barcode?.product
      }
      const dimension = this.activationDataDimension(product)
      const packageInfo = {
        ...(product && { productName: product.name }),
        ...(this.isTrackingNumberAvailable &&
          !isEmpty(this.trackingNumber) && {
            [this.trackingNumberLabel]: this.trackingNumber,
          }),
        ...(this.isExternalIdAvailable &&
          !isEmpty(this.externalId) && {
            [this.externalIdLabel]: this.externalId,
          }),
        ...(this.isDimensionAvailable && {
          ...dimension,
        }),
        ...this.customFieldValues,
      }
      const productInfo = this.product
      return { ...packageInfo, ...productInfo }
    } catch (err) {
      console.error(err)
      return {}
    }
  }

  activationDataDimension(product: IProduct) {
    const rawDimensionData: IDimension = {
      ...product?.dimension,
      ...this.barcode?.activationData?.dimension,
    }
    const localeNumber = {
      width: rawDimensionData?.width?.toLocaleString(),
      height: rawDimensionData?.height?.toLocaleString(),
      weight: rawDimensionData?.weight?.toLocaleString(),
      depth: rawDimensionData?.depth?.toLocaleString(),
    }
    return localeNumber
  }

  get isTrackingNumberAvailable() {
    return this.barcodeTypeInfo?.activationFields?.trackingNumber?.isAvailable ?? false
  }

  get isExternalIdAvailable() {
    return this.barcodeTypeInfo?.activationFields?.externalId?.isAvailable ?? false
  }

  get isImageDataAvailable() {
    return this.barcodeTypeInfo?.activationFields?.imageData?.isAvailable ?? false
  }

  get isDimensionAvailable() {
    return this.barcodeTypeInfo?.activationFields?.dimension?.isAvailable ?? false
  }

  // --------------------------------------------------------------------------------

  get trackingNumber(): string {
    return this.barcode?.activationData?.trackingNumber
  }

  get externalIdLabel(): string {
    return this.$t('externalId')
  }

  get externalId(): string {
    return this.barcode?.activationData?.externalId
  }

  flattenFields(obj: IActivationData): Record<string, string | string[] | unknown> {
    const fields: {
      [key: string]: unknown
    } = { ...obj }
    return Object.keys(fields).reduce((acc, cur) => {
      if (cur.match(/^custom[a-zA-Z]/g)) {
        return { ...acc, ...(fields[cur] as object) }
      }
      return { ...acc }
    }, {})
  }

  get customFieldValues() {
    const parsedCustomFields = {} as Record<string, string | string[]>
    if (!this.barcodeTypeInfo) return parsedCustomFields
    const customFieldsDefinition = this.barcodeTypeInfo?.activationFields?.customFields || {}
    parseCustomFieldsValue(parsedCustomFields, customFieldsDefinition, this.barcode)

    return parsedCustomFields
  }
}
