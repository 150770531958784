<template>
  <BarcodeLabel v-for="(barcode, index) in barcodes" :key="index" :type="barcode.barcodeType" :label="barcode?.label"
    :subLabel="barcode?.id" :labelOnly="!barcode?.id" />
</template>

<script lang="ts">
import { IDisplayBarcodes, IProject } from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import BarcodeKeyValueInfoMixin from './mixins/BarcodeKeyValueInfoMixin.vue'
import BarcodeLabel from '@/components/BarcodeLabel.vue'
import { addLabelToBarcode } from '@/utils/helpers'

@Options({
  name: 'BarcodeLabelList',
  components: {
    BarcodeLabel,
  },
})
export default class BarcodeLabelList extends mixins(BarcodeKeyValueInfoMixin) {
  @Prop({ type: Object }) readonly barcodes!: IDisplayBarcodes[]

  mounted() {
    addLabelToBarcode(this.barcodes, this.project)
  }

  get project(): IProject {
    return this.$store.state.project.details
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixins.scss';
</style>
